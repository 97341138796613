import React from 'react';

interface FeatureProps {
  imgSrc: string;
  title: string;
  description: string;
}

const Feature: React.FC<FeatureProps> = ({ imgSrc, title, description }) => (
  <div className="col-lg-3 col-md-4 col-sm-6 text-center pb-md-2 mb-3 mb-lg-4">
    <div className="d-inline-block bg-secondary rounded-circle p-3 mb-4">
      <img src={imgSrc} width="32" alt="Icon" />
    </div>
    <h3 className="h5 pb-1 mb-2">{title}</h3>
    <p className="fs-sm">{description}</p>
  </div>
);

const FeaturesSection: React.FC = () => (
  <section className="container">
    <h2 className="h1 text-center pb-4 pb-lg-5">Automate Your eBay Business</h2>
    <div className="row">
      <Feature
          imgSrc="assets/img/landing/saas-1/features/ai.svg"
          title="AI-Powered Marketing Insights"
          description="Unlock data-driven success with our AI-powered marketing insights. 
          Analyze campaigns, budgets, clicks, and conversions. Get personalized recommendations
          to maximize ROI and drive sales in your Standard and Advanced Marketing Campaigns."
        />
        <Feature
          imgSrc="assets/img/landing/saas-1/features/code.svg"
          title="Image Enhancement Suite"
          description="Effortlessly enhance your eBay product images in bulk! Our suite 
          allows you to add labels, logos, watermarks, and more. Make your listings stand 
          out and attract buyers."
        />
        <Feature
          imgSrc="assets/img/landing/saas-1/features/chat.svg"
          title="Custom Offer Manager"
          description="Streamline your sales strategy with our Custom Offer Manager. 
            This feature empowers you to craft personalized offers for your buyers, 
            enhancing engagement and increasing conversion rates. Tailor your deals 
            to meet customer needs and watch your business grow."
        />
        <Feature
          imgSrc="assets/img/landing/saas-1/features/analytics.svg"
          title="Updating Inventory and Prices"
          description="Keep your listings up-to-date effortlessly. Our system automates 
            the synchronization of inventory levels and pricing, ensuring your eBay store 
            reflects the most current information. Say goodbye to manual updates and hello 
            to real-time accuracy."
        />
        <Feature
          imgSrc="assets/img/landing/saas-1/features/bell.svg"
          title="Sending Orders"
          description="Our platform simplifies the order dispatch process. With automated order 
          sending, you can ensure that your products reach your customers swiftly and efficiently. 
          Reduce manual errors and enhance customer satisfaction with timely deliveries."
        />
        <Feature
          imgSrc="assets/img/landing/saas-1/features/tasks.svg"
          title="Copying Listings, Item Specifics and Fitments"
          description="Expand your store with ease. Duplicate your successful listings, 
          including detailed item specifics and fitments, to save time and maintain consistency 
          across your products. Our tool makes scaling up your inventory a seamless experience."
        />
        <Feature
          imgSrc="assets/img/landing/saas-1/features/calendar.svg"
          title="Updating Tracking Numbers"
          description="Enhance post-purchase customer experience by providing timely tracking updates. 
          Our system automatically updates tracking numbers, keeping your customers informed every step 
          of the way from purchase to delivery."
        />
        <Feature
          imgSrc="assets/img/landing/saas-1/features/add-group.svg"
          title="Multiple Assignees"
          description="Collaborate effectively with your team. Assign tasks and responsibilities 
          to multiple team members, ensuring efficient management of your eBay store. Our feature 
          fosters teamwork and accountability, driving productivity."
        />
        <Feature
          imgSrc="assets/img/landing/saas-1/features/headset.svg"
          title="Support 24/7"
          description="Never face an issue alone. Our dedicated support team is available around 
          the clock to assist you with any queries or concerns. Experience peace of mind with our 
          24/7 support, knowing that we're here to help whenever you need us."
        />
        <Feature
          imgSrc="assets/img/landing/saas-1/features/shield.svg"
          title="Data Security"
          description="Protect your sensitive data with our robust security measures. We prioritize 
          the safety of your business information, employing advanced encryption and security protocols 
          to safeguard your data against unauthorized access."
        />
    </div>
  </section>
);

export default FeaturesSection;