import React, { useEffect, useState } from 'react';
import {BrowserRouter, Routes, Route, Navigate} from  "react-router-dom";
import Dashboard from './components/Dashboard';
import EbayRedirectHandler from './components/EbayRedirectHandler';
import Footer from './components/Footer';
import FrontPage from './components/FrontPage';
import LoginForm from './components/LoginForm';
import Navbar from './components/Navbar';
import RegistrationForm from './components/RegistrationForm';



function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const isAuthenticated = await checkAuthentication();
      setAuthenticated(isAuthenticated);
    };

    checkAuth();
  }, []);

  //Page loading scripts
  useEffect(() => {
    const preloader = document.querySelector('.page-loading');
    if (preloader) {
      preloader.classList.remove('active');
      setTimeout(() => {
        preloader.remove();
      }, 1000);
    }
  }, []);

  async function checkAuthentication() {
    try {
      const url = process.env.REACT_APP_CHECK_LOGIN_URL;
      if (!url) {
          throw new Error('CHECK_LOGIN_URL not defined');
      }
      const response = await fetch(url);
  
      if (!response.ok) {
        throw new Error('Response is not OK');
      }
  
      const data = await response.json();
  
      return data.isAuthenticated;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }
  return (
    <div className="App">
      {/* Page wrapper for sticky footer */}
      {/* Wraps everything except footer to push footer to the bottom of the page if there is little content */}
      <main className="page-wrapper">
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<FrontPage />} />
            <Route path="/register" element={<RegistrationForm onRegister={() => setAuthenticated(true)}/>} />
            <Route path="/login" element={<LoginForm onLogin={() => setAuthenticated(true)} />} />
            <Route path="/ebay-redirect" element={<EbayRedirectHandler onRedirect={() => setAuthenticated(true)}/> } />
            <Route path="/dashboard" element={authenticated ? <Dashboard /> : <Navigate to="/login" />} />
          </Routes>
        </BrowserRouter>
      </main>
      <Footer />
    </div>
  );
}

export default App;