import React, { Component } from 'react'

class Navbar extends Component {
    render() {
        return (
            <div>
                {/* Navbar */}
                {/* Remove "navbar-sticky" classNameName to make navigation bar scrollable with the page */}
                <header className="header navbar navbar-expand-lg navbar-dark position-absolute navbar-sticky">
                    <div className="container px-3">
                        <a href="/" className="navbar-brand pe-3">
                            <img src="assets/img/logo.svg" width="47" alt="Optimai"/>
                            Optimai
                        </a>
                        <div id="navbarNav" className="offcanvas offcanvas-end bg-dark">
                            <div className="offcanvas-header border-bottom border-light">
                                <h5 className="offcanvas-title text-white">Menu</h5>
                                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a href="#features" className="nav-link">
                                            Features
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#pricing" className="nav-link">
                                            Pricing
                                        </a>
                                    </li>

                                    <li className="nav-item d-none ms-lg-auto">
                                        <a href="/login"  className="nav-link">
                                            Log In
                                        </a>
                                    </li>   
                                    <li className="nav-item d-none">
                                        <a href="#waitlist"  className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex btn-transparent">
                                            Book a demo
                                        </a>
                                    </li>
                                    <li className="nav-item ms-lg-auto">
                                        <a href="#waitlist"  className="btn btn-primary btn-sm fs-sm rounded d-lg-inline-flex">
                                            Join The Waitlist
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </header>
            </div>
        )
    }
}

export default Navbar;