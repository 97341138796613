import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap';

type Props = {
    onConnectionSuccess: () => void;
    onConnect: () => Promise<void>
};

const ConnectToSalesChannels = (props: Props) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleConnect = async () => {
        setIsLoading(true);
        const result = await connectToEbay();

        if (result.success) {
            props.onConnectionSuccess();
        }
        setIsLoading(false);
    };

    const token = localStorage.getItem('authToken');
    if (!token) {
        return <div>Not logged in</div>;
    }
    const connectToEbay = async () => {
        const url = process.env.REACT_APP_GET_USER_ACCESS_CODE_FOR_MARKETING_CAMPAIGNS_URL;
        if (!url) {
            throw new Error('REACT_APP_GET_USER_ACCESS_CODE_FOR_MARKETING_CAMPAIGNS_URL not defined');
        }
        const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                        'Authorization': `Token ${token}`,
                },
        });

        // Redirect to eBay authorization page
        const data = await response.json(); 
        if (data.token_exists) {
            console.log('Token for user already exists');
            return { success: true };
        } else if (data.url) {
            window.location.href = data.url;
        } else {
            console.log('Failed to get eBay authorization URL');
            return { success: false };
        }
        
        if (response.ok) {
            console.log('eBay authorization is successful');
            return { success: true };
        } else {
            console.log('eBay authorization failed');
            return { success: false };
        }
    };

    const ebayLogo = process.env.PUBLIC_URL + '/ebay_logo.jpg';

    return (
        <div>
            <h3 className="fs-2 text-body-emphasis">Analyze eBay Marketing Campaigns</h3>
            <p>Optimai will Analyze your marketing campaigns and offer actionable insights on how to increase your ROAS.</p>
            <button 
                type="button" 
                className="btn btn-primary" 
                onClick={handleConnect} // Call handleConnect when the button is clicked
                disabled={isLoading}
            >
                {isLoading ? <Spinner animation="border" /> : 'Analyze Marketing Campaigns'}
            </button>
        </div>
    );
};

export default ConnectToSalesChannels;

