import React from 'react';

interface TabProps {
  id: string;
  icon: string;
  label: string;
  isActive: boolean;
}

export const Tab: React.FC<TabProps> = ({ id, icon, label, isActive }) => (
  <li className="nav-item" role="presentation">
    <button className={`nav-link text-nowrap ${isActive ? 'active' : ''}`} id={`${id}-tab`} data-bs-toggle="tab" data-bs-target={`#${id}`} type="button" role="tab" aria-controls={id} aria-selected={isActive}>
      <i className={`bx ${icon} fs-lg opacity-60 me-2`}></i>
      {label}
    </button>
  </li>
);