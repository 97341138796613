import React from 'react';

const HeroSection = () => {
    return (
        <section className="position-relative overflow-hidden py-4 mb-3">
            <div className="container pt-lg-3">
                <div className="row flex-lg-nowrap">
                    <div className="col-lg-6 col-xl-5 text-center text-lg-start pt-5 mt-xl-4">
                        <h1 className="display-4 pt-5 pb-2 pb-lg-3 mt-sm-2 mt-lg-5">eBay Business Management Assistant You Gonna Love</h1>
                        <p className="fs-lg mb-4 mb-lg-5">We offer you a new generation of eBay Business management system. Automate all your tasks in one flexible tool.</p>
                        <a href="#waitlist" className="btn btn-primary btn-lg">Join the Exclusive Waitlist</a>
                        <div className="pt-5 mt-xl-5">
                            <h6 className="pt-xl-3 pb-3 pb-lg-4">Top eBay stores use our tool to work efficiently and beat competition</h6>
                            {/* <div className="d-flex justify-content-center justify-content-lg-start mx-xl-n2">
                                <a href="#" className="d-block me-2">
                                    <img src="assets/img/brands/07.svg" width="135" alt="Logo" />
                                </a>
                                <a href="#" className="d-block me-2">
                                    <img src="assets/img/brands/04.svg" width="135" alt="Logo" />
                                </a>
                                <a href="#" className="d-block me-2">
                                    <img src="assets/img/brands/02.svg" width="135" alt="Logo" />
                                </a>
                                <a href="#" className="d-block me-2">
                                    <img src="assets/img/brands/05.svg" width="135" alt="Logo" />
                                </a>
                            </div> */}
                        </div>
                    </div>

                    {/* <!-- Layer parallax --> */}
                    <div className="parallax mt-4 ms-4 me-lg-0 ms-lg-n5 ms-xl-n3 mt-lg-n4">
                        <div className="parallax-layer" data-depth="0.1">
                            <img src="assets/img/landing/saas-1/hero/layer01.png" width="1416" alt="Layer" />
                        </div>
                        <div className="parallax-layer zindex-2" data-depth="0.15">
                            <img src="assets/img/landing/saas-1/hero/layer02.png" alt="Layer" />
                        </div>
                        <div className="parallax-layer zindex-2" data-depth="0.35">
                            <img src="assets/img/landing/saas-1/hero/layer03.png" alt="Layer" />
                        </div>
                        <div className="parallax-layer zindex-2" data-depth="0.25">
                            <img src="assets/img/landing/saas-1/hero/layer04.png" alt="Layer" />
                        </div>
                        <div className="parallax-layer zindex-2" data-depth="0.4">
                            <img src="assets/img/landing/saas-1/hero/layer05.png" alt="Layer" />
                        </div>
                        <div className="parallax-layer zindex-2" data-depth="0.28">
                            <img src="assets/img/landing/saas-1/hero/layer06.png" alt="Layer" />
                        </div>
                        <div className="parallax-layer zindex-2" data-depth="0.35">
                            <img src="assets/img/landing/saas-1/hero/layer07.png" alt="Layer" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HeroSection;