import React, { FormEvent, useState } from 'react';

const SignUpCTA: React.FC = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    // Basic input validation
    if (!email ) {
      alert('Please enter a valid email address');
      return;
    }

    const url = process.env.REACT_APP_FORWARD_EMAIL_URL;
      if (!url) {
          throw new Error('SUBMIT_URL not defined');
      }

    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({"email": email}),
      });

      if (response.ok) {
        console.log('Email added to waitlist');
        alert('Your email was added to the waitlist! We will notify you when we are ready to launch.');
      } else {
        const errorData = await response.json();
        const errors = Object.values(errorData).flat().join(' ');
        alert('There was an error adding your email to the waitlist. Please email us at info@optimai.xyz');
      }
   
  };

  return (
    <section className="container">
      <div className="bg-dark border border-light rounded-3 py-5 px-4 px-sm-0">
        <div className="row justify-content-center py-sm-2 py-lg-5">
          <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 text-center">
            <h2 className="h1 text-light mb-4">Ready to Join the Exclusive Waitlist?</h2>
            {/* <p className="fs-lg text-light opacity-70 pb-4 mb-3">Organize your tasks with a 14-day free trial</p> */}

            {/* Desktop form */}
            <form className="input-group input-group-lg d-none d-sm-flex needs-validation mb-3" onSubmit={handleSubmit} noValidate>
              <input type="email" className="form-control rounded-start ps-5" placeholder="Your email" required value={email} onChange={e => setEmail(e.target.value)} />
              <i className="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
              <div className="invalid-tooltip position-absolute top-100 start-0">Please provide a valid email address.</div>
              <button type="submit" className="btn btn-primary">Join for free</button>
            </form>

            {/* Mobile form */}
            <form className="needs-validation d-sm-none mb-3" onSubmit={handleSubmit} noValidate>
              <div className="position-relative mb-3">
                <input type="email" className="form-control form-control-lg rounded-start ps-5" placeholder="Your email" required value={email} onChange={e => setEmail(e.target.value)} />
                <i className="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5"></i>
                <div className="invalid-tooltip position-absolute top-0 start-0 mt-n4">Please provide a valid email address.</div>
              </div>
              <button type="submit" className="btn btn-primary btn-lg w-100">Join for free</button>
            </form>
            {/* <p className="fs-sm text-light opacity-50 mb-0">No subscriptions. No annual fees. No lock-ins.</p> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUpCTA;