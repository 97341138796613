import React from 'react';

interface ListItemProps {
  text: string;
}

const ListItem: React.FC<ListItemProps> = ({ text }) => (
  <li className="d-flex align-items-start pb-1 mb-2">
    <i className="bx bx-check-circle text-primary fs-xl me-2 mt-1"></i>
    {text}
  </li>
);

const DashboardSection: React.FC = () => (
  <section className="container pt-3 pt-md-4 pt-lg-5 pb-2 mt-lg-2 mt-xl-4">
  <div className="row align-items-center">
    <div className="rellax col-md-6" data-rellax-percentage="0.5" data-rellax-speed="-0.6" data-disable-parallax-down="lg">
      <img src="assets/img/landing/saas-1/dashboard.png" className="d-block mx-auto" width="746" alt="Dashboard" />
    </div>
    <div className="rellax col-md-6 col-xl-5 offset-xl-1 d-flex d-sm-block flex-column" data-rellax-percentage="0.5" data-rellax-speed="0.8" data-disable-parallax-down="lg">
      <h2 className="pb-3 pt-2 pt-md-0">Elevate Your eBay Sales</h2>
      <ul className="list-unstyled pb-2">
        <ListItem text="Automate offers and negotiations with Custom Offer Manager" />
        <ListItem text="Real-time inventory and pricing updates at your fingertips" />
        <ListItem text="Seamless order processing for enhanced buyer satisfaction" />
        <ListItem text="Effortless listing replication to expand your market reach" />
        <ListItem text="Instant tracking updates for a transparent buyer experience" />
      </ul>
      <a href="#waitlist" className="btn btn-primary">
        Learn more
        <i className="bx bx-right-arrow-alt fs-xl ms-2"></i>
      </a>
    </div>
  </div>
</section>
);

export default DashboardSection;