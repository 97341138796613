import React from 'react';

type Testimonial = {
  quote: string;
  author: string;
  role: string;
  avatar: string;
};

const testimonials: Testimonial[] = [
  {
    quote: 'As a top eBay seller, I’ve been using Optimai, and it’s a game-changer. The Custom Offer Manager streamlines sales, and real-time inventory updates keep my store accurate. The 24/7 support? Invaluable. If you’re serious about eBay success, Optimai is the secret weapon you need. 🚀✨',
    author: 'Roman P.',
    role: 'CEO at Autoshopping24',
    avatar: 'assets/img/avatar/00.jpg',
  },
  // ... other testimonials
];

const Testimonials: React.FC = () => {
  return (
    <section className="bg-secondary py-5">
      <div className="container py-2 py-md-3 py-lg-4 py-xl-5">
        <div className="row row-cols-1 row-cols-md-2 gx-3 gx-lg-4">
          <div className="col-md-6 mb-3 mb-md-0">
            <div className="card border-0 bg-dark h-100 p-xl-4">
              <div className="card-body">
                {/* <h2 className="display-1 text-light">Top</h2> */}
                <p className="lead fw-medium text-light">
                  <span className="opacity-70">Top eBay stores are taking advantage of our</span> <span className="fw-bold">Business Automation Tool</span><span className="opacity-70">. It’s a new efficient way to automate your business tasks and stand out from competition.</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card border-0 shadow-sm p-4 p-xxl-5">
              <div className="d-flex justify-content-between pb-4 mb-2">
                <span className="btn btn-icon btn-primary btn-lg shadow-primary pe-none">
                  <i className="bx bxs-quote-left"></i>
                </span>
              </div>
              {testimonials.map((testimonial, index) => (
                <div key={index}>
                  <figure className="card h-100 position-relative border-0 bg-transparent">
                    <blockquote className="card-body p-0 mb-0">
                      <p className="fs-lg mb-0">{testimonial.quote}</p>
                    </blockquote>
                    <figcaption className="card-footer border-0 d-flex align-items-center w-100 pb-2">
                      <img src={testimonial.avatar} width="60" className="rounded-circle" alt={testimonial.author} />
                      <div className="ps-3">
                        <h6 className="fw-semibold lh-base mb-0">{testimonial.author}</h6>
                        <span className="fs-sm text-muted">{testimonial.role}</span>
                      </div>
                    </figcaption>
                  </figure>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;