import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface RegistrationformProps {
  onRegister: () => void;
}

interface LocationState {
  email: string;
}

function RegistrationForm({ onRegister }: RegistrationformProps) {
  const location = useLocation();
  const initialEmail = (location.state as LocationState)?.email || '';
  const [email, setEmail] = useState(initialEmail);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    // Basic input validation
    if (!email || !password) {
      alert('All fields are required');
      return;
    }

    const url = process.env.REACT_APP_REGISTER_URL;
      if (!url) {
          throw new Error('REGISTER_URL not defined');
      }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "email":email, "first_name":email, "password":password }),
    });

    if (response.ok) {
      const data = await response.json();
      const authToken = data.token;
      localStorage.setItem('authToken', authToken);
      console.log('User registered successfully');
      onRegister();
      navigate('/dashboard');
    } else {
      const errorData = await response.json();
      const errors = Object.values(errorData).flat().join(' ');
      setError(errors || '');
    }

  };

  return (
    // <!-- Page content -->
      <section className="position-relative h-100 pt-5 pb-4">

        {/* <!-- Sign up form --> */}
        <div className="container d-flex flex-wrap justify-content-center justify-content-xl-start h-100 pt-5">
          <div className="w-100 align-self-end pt-1 pt-md-4 pb-4" style={{ maxWidth: '526px' }}>
            <h1 className="text-center text-xl-start">Create Account</h1>
            <form className="needs-validation" noValidate onSubmit={handleRegister}>
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="position-relative mb-4">
                    <input type="email" id="email" className="form-control form-control-lg" placeholder="Work email *" required value={email} onChange={e => setEmail(e.target.value)}/>
                    <div className="invalid-feedback position-absolute start-0 top-100">Please enter a valid email address!</div>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="password-toggle">
                    <input type="password" id="password" className="form-control form-control-lg" placeholder="Password *" required value={password} onChange={e => setPassword(e.target.value)}/>
                    <label className="password-toggle-btn" aria-label="Show/hide password">
                      <input className="password-toggle-check" type="checkbox"/>
                      <span className="password-toggle-indicator"></span>
                    </label>
                    <div className="invalid-feedback position-absolute start-0 top-100">Please enter a password!</div>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary shadow-primary btn-lg w-100">Sign up</button>
            </form>
            {/* <hr className="my-4" />
            <h6 className="text-center mb-4">Or sign up with your social network</h6>
            <div className="row row-cols-1 row-cols-sm-2">
              <div className="col mb-3">
                <a href="#" className="btn btn-icon btn-secondary btn-google btn-lg w-100" >
                  <i className="bx bxl-google fs-xl me-2"></i>
                  Google
                </a>
              </div>
              <div className="col mb-3">
                <a href="#" className="btn btn-icon btn-secondary btn-facebook btn-lg w-100">
                  <i className="bx bxl-facebook fs-xl me-2"></i>
                  Facebook
                </a>
              </div>
            </div> */}
            <div className="text-center mb-4">
              <label htmlFor="terms" className="form-check-label fs-base">By clicking sign up, I agree to <a href="#">Terms &amp; Conditions</a></label>
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{height: '100%'}}>
              <div className="text-center mb-4">
                <p className="text-center text-xl-start pb-3 mb-3">Already have an Optimai account? <a href="/login">Sign in</a></p>
              </div>
            </div>
          </div>
        </div>

      {/* <!-- Background --> */}
      <div className="position-absolute top-0 end-0 w-50 h-100 bg-position-center bg-repeat-0 bg-size-cover d-none d-xl-block" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/account/signin-bg.jpg)` }}></div>
    </section>
  );
}

export default RegistrationForm;