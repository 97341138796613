import React, { useState } from 'react';
import '../App.css';
import ConnectToSalesChannels from './ConnectToSalesChannels';
import Main from './Main';
import Nav from './Navbar';
import SideBar from './Sidebar';
import SendOffersToInterestedClients from './SendOffersToInterestedClients';


const Dashboard = () => {
    const [isConnected, setIsConnected] = useState(false);

    const handleConnectionSuccess = () => {
        setIsConnected(true);
      };

      const handleConnect = async () => {
        
        // ...
    };

    return (
        <div>
            <Nav />
            <div className="App">
                <div className="row">
                    <SideBar />
                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                        <div className="container px-4 py-5" id="hanging-icons">
                            <h2 className="pb-2 border-bottom">Dashboard</h2>
                            <div className="row g-4 py-5 row-cols-1 row-cols-lg-2">
                                <div className="col d-flex align-items-start">
                                    <div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                    <svg className="bi" width="1em" height="1em"><use xlinkHref="#toggles2"></use></svg>
                                    </div>
                                        <ConnectToSalesChannels 
                                            onConnectionSuccess={handleConnectionSuccess} 
                                            onConnect={handleConnect}
                                        />
                                </div>
                                <div className="col d-flex align-items-start">
                                    <div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                        <svg className="bi" width="1em" height="1em"><use xlinkHref="#cpu-fill"></use></svg>
                                    </div>
                                    <div>
                                        <SendOffersToInterestedClients 
                                                onConnectionSuccess={handleConnectionSuccess} 
                                                onConnect={handleConnect}
                                            />
                                    </div>
                                </div>
                                {/* <div className="col d-flex align-items-start">
                                    <div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                        <svg className="bi" width="1em" height="1em"><use xlinkHref="#tools"></use></svg>
                                        </div>
                                        <div>
                                        <h3 className="fs-2 text-body-emphasis">Coming Soon</h3>
                                        <p>Another cool feature.</p>
                                        <a href="#" className="btn btn-primary">
                                            Stay Tuned!
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* {!isConnected && (
                            
                        )}
                        {isConnected && <Main />} */}
                    </main>
                </div>
            </div> 
        </div>
    );
}

export default Dashboard;