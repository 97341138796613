import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface EbayRedirectHandlerProps {
  onRedirect: () => void;
}

const EbayRedirectHandler = ({ onRedirect }: EbayRedirectHandlerProps) => {
  const navigate = useNavigate();

  const checkEbayAuthorization = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code') || '';
    const state = urlParams.get('state') || '';
    return { authorizationCode, state };
  };
  
  const sendAuthorizationCodeToBackend = async ({ authorizationCode, state }: { authorizationCode: string; state: string }) => {
    const url = process.env.REACT_APP_SAVE_AUTHORIZATION_CODE_URL;
    if (!url) {
        throw new Error('SAVE_AUTHORIZATION_CODE_URL not defined');
    }
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ authorizationCode, state }),
    });
      return response.ok;
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  useEffect(() => {
    const { authorizationCode, state } = checkEbayAuthorization();
  
    if (authorizationCode) {
      const sendCodeAndNavigate = async () => {
        const success = await sendAuthorizationCodeToBackend({ authorizationCode, state });
  
        if (success) {
          onRedirect();
          navigate('/dashboard');
        } else {
          // Handle error
        }
      };
  
      sendCodeAndNavigate();
    }
  }, []);

  return <Spinner />;
};

export default EbayRedirectHandler;