import React, { useState } from 'react';
import PriceContent from './PriceContent';

const PricingComponent: React.FC = () => {
  const [isAnnual, setIsAnnual] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAnnual(event.target.checked);
  };
  
  const cardData = [
    {
      title: 'Starter Plan',
      monthlyPrice: '$99.99',
      annualPrice: '$999.99', // 2 months free on annual plan
      imgSrc: 'assets/img/landing/saas-1/pricing/basic.png',
      features: [
        { text: 'Orders/month Included: Up to 500', available: true },
        { text: 'Store Integrations: 1 eBay Store Integration', available: true },
        { text: 'Vendor Integrations: Up to 3', available: true },
        { text: 'Custom Offer Manager', available: true },
        { text: 'Real-time Inventory Updates', available: true },
        { text: 'Updating Tracking Numbers', available: true },
        { text: '24/7 Email Support', available: true },
        { text: 'Copying Listings, Item Specifics, and Fitments', available: false },
      ],
      footer: '<a href="#waitlist" class="btn btn-outline-primary w-100">Join Waitlist</a>',
    },
    {
      title: 'Growth Plan',
      monthlyPrice: '$349.99',
      annualPrice: '$3499.99', // 2 months free on annual plan
      imgSrc: 'assets/img/landing/saas-1/pricing/standard.png',
      features: [
        { text: 'Orders/month Included: Up to 2000', available: true },
        { text: 'Store Integrations: 1 eBay Store Integration', available: true },
        { text: 'Vendor Integrations: Up to 5', available: true },
        { text: 'Custom Offer Manager', available: true },
        { text: 'Real-time Inventory Updates', available: true },
        { text: 'Updating Tracking Numbers', available: true },
        { text: 'Copying Listings, Item Specifics, and Fitments', available: true },
        { text: '24/7 Email and Chat Support', available: true },
        { text: 'Multiple Assignees for Tasks', available: false },
      ],
      footer: '<a href="#waitlist" class="btn btn-light w-100">Join Waitlist</a>',
    },
    {
      title: 'Ultimate Plan',
      monthlyPrice: '$699.99',
      annualPrice: '$6999.99', // 2 months free on annual plan
      imgSrc: 'assets/img/landing/saas-1/pricing/ultimate.png',
      features: [
        { text: 'Orders/month Included: Up to 10,000', available: true },
        { text: 'Store Integrations: 3 eBay Store Integration', available: true },
        { text: 'Vendor Integrations: Up to 10', available: true },
        { text: 'Custom Offer Manager', available: true },
        { text: 'Real-time Inventory Updates', available: true },
        { text: 'Updating Tracking Numbers', available: true },
        { text: 'Copying Listings, Item Specifics, and Fitments', available: true },
        { text: 'Priority 24/7 Email and Chat Support', available: true },
        { text: 'Multiple Assignees for Tasks', available: true },
      ],
      footer: '<a href="#waitlist" class="btn btn-outline-primary w-100">Join Waitlist</a>',
    },
  ];

  return (
    <section className="container py-5 my-md-2 my-lg-4 my-xl-5">
      <h2 className="h1 text-center pb-3 pb-md-4">Transparent Pricing for You</h2>
      <div className="price-switch-wrapper mb-n2">
        <div className="form-check form-switch price-switch justify-content-center mt-2 mb-4">
          <input 
            type="checkbox" 
            className="form-check-input" 
            id="pricing" 
            checked={isAnnual} 
            onChange={handleInputChange} 
          />
          <label className="form-check-label" htmlFor="pricing">{isAnnual ? 'Annually' : 'Monthly'}</label>
          {isAnnual && <label className="form-check-label d-flex align-items-start" htmlFor="pricing"><span className="text-danger fs-xs fw-semibold mt-n2 ms-2">2 months free</span></label>}
        </div>
        <div className="row pb-4">
            {cardData.map((card, index) => (
                <div key={index} className="col-12 col-md-4">
                    <div className={`card h-100 border-0 shadow-sm ${card.title === 'Growth Plan' ? 'bg-primary' : card.title === 'Basic' ? 'bg-secondary' : 'bg-tertiary'} p-xxl-3`}>
                        <PriceContent {...card} isAnnual={isAnnual} />
                    </div>
                </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default PricingComponent;