import React from 'react';
import { Tab } from './Tab';
import { TabContent } from './TabContent';

export const MainComponent: React.FC = () => (
  <section className="container pt-5 pb-2 mt-3 mt-sm-4 mt-xl-5">
    <h2 className="h1 text-center pb-3 pb-lg-4">One Tool — Endless Use Cases</h2>

    <ul className="nav nav-tabs flex-nowrap justify-content-lg-center overflow-auto pb-2 mb-3 mb-lg-4" role="tablist">
      <Tab id="offer-management" icon="bx bx-gift" label="Offer Management" isActive={true} />
      <Tab id="inventory-sync" icon="bx bx-sync" label="Inventory Sync" isActive={false}/>
      <Tab id="order-fulfillment" icon="bx bx-package" label="Order Fulfillment" isActive={false}/>
      <Tab id="listing-expansion" icon="bx bx-copy" label="Listing Expansion" isActive={false}/>
      <Tab id="tracking-updates" icon="bx bx-map" label="Tracking Updates" isActive={false}/>
    </ul>

    <div className="tab-content bg-secondary rounded-3 py-4">
      <TabContent 
        id="offer-management" 
        title="Custom Offer Management" 
        content="Empower your sales with our Custom Offer Manager, designed to personalize your deals and boost engagement." 
        imgSrc="assets/img/landing/saas-1/use-cases/01.png" 
        imgWidth={595} 
        isActive={true} 
      />
      <TabContent 
        id="inventory-sync" 
        title="Inventory & Pricing Sync" 
        content="Automate inventory and pricing updates, keeping your eBay store current with zero effort." 
        imgSrc="assets/img/landing/saas-1/use-cases/02.png" 
        imgWidth={595} 
        isActive={false} 
      />
      <TabContent 
        id="order-fulfillment" 
        title="Efficient Order Fulfillment" 
        content="Streamline your order dispatch process with automated sending and enhance customer satisfaction." 
        imgSrc="assets/img/landing/saas-1/use-cases/03.png" 
        imgWidth={595} 
        isActive={false} 
      />
      <TabContent 
        id="listing-expansion" 
        title="Easy Listing Expansion" 
        content="Quickly replicate your successful listings, including all specifics and fitments, for rapid store growth." 
        imgSrc="assets/img/landing/saas-1/use-cases/04.png" 
        imgWidth={595} 
        isActive={false} 
      />
      <TabContent 
        id="tracking-updates" 
        title="Real-Time Tracking Updates" 
        content="Keep your customers informed with automatic tracking updates from purchase to delivery." 
        imgSrc="assets/img/landing/saas-1/use-cases/01.png" 
        imgWidth={595} 
        isActive={false} 
      />
    </div>
  </section>
);