import React from 'react';
import HeroSection from './FrontPageHeroSection';
import FeaturesSection from './FrontPageFeatures';
import DashboardSection from './FrontPageDashboard';
import { MainComponent } from './MainComponent';
import PricingComponent from './PricingComponent';
import Testimonials from './Testimonials';
import SignUpCTA from './SignUpCTA';


const FrontPage = () => {
    return (
        <>
            <HeroSection />
            <div id="features">
                <FeaturesSection />
            </div>
            <DashboardSection />
            <MainComponent />
            <div id="pricing">
              <PricingComponent />
            </div>
            <Testimonials />
            <div id="waitlist">
                <SignUpCTA />
            </div>
        </>
    );
}

export default FrontPage;