import React from 'react';

interface PriceContentProps {
  title: string;
  monthlyPrice: string;
  annualPrice: string;
  imgSrc: string;
  features: {
    text: string;
    available: boolean;
  }[];
  footer: string;
  isAnnual: boolean;
}

const PriceContent: React.FC<PriceContentProps> = ({ title, monthlyPrice, annualPrice, features, imgSrc, footer, isAnnual }) => {
  const price = isAnnual ? annualPrice : monthlyPrice;

  return (
      <div className="card-body">
        <div className="d-flex align-items-center pb-2 pb-md-3 mb-4">
          <div className="flex-shrink-0 bg-secondary rounded-3">
              <img src={imgSrc} width="84" alt="Icon" />
          </div>
          <div className="ps-4">
            <h3 className="fs-lg fw-normal text-body mb-2">{title}</h3>
            <h4 className="h3 lh-1 mb-0">
              {isAnnual ? (
                <span data-annual-price>{annualPrice}</span>
              ) : (
                <span data-monthly-price>{monthlyPrice}</span>
              )}
              <span className="text-body fs-sm fw-normal"> / per {isAnnual ? 'year' : 'month'}</span>
            </h4>
          </div>
        </div>
        <ul className="list-unstyled fs-sm pb-md-3 mb-3">
          {features.map((feature, index) => (
            <li key={index} className="d-flex mb-2">
              {feature.available ? (
                <i className={`bx bx-check fs-xl ${title === 'Standard' ? 'text-white' : title === 'Basic' ? 'text-secondary' : 'text-tertiary'} me-1`}></i>
              ) : (
                <i className="bx bx-x fs-xl text-muted me-1"></i>
              )}
              <span className={feature.available ? 'text-body' : 'text-muted'}>{feature.text}</span>
            </li>
          ))}
        </ul>
        <div className="card-footer border-0 pt-0 pb-4" dangerouslySetInnerHTML={{ __html: footer }} />
      </div>
    );
  };
  
  export default PriceContent;