import React from 'react';

interface TabContentProps {
  id: string;
  title: string;
  content: string;
  imgSrc: string;
  imgWidth: number;
  isActive: boolean;
}

export const TabContent: React.FC<TabContentProps> = ({ id, title, content, imgSrc, imgWidth, isActive }) => (
  <div className={`tab-pane fade ${isActive ? 'show active' : ''}`} id={id} role="tabpanel" aria-labelledby={`${id}-tab`}>
    <div className="row align-items-center pt-3 pt-sm-4 pt-md-0 px-3 px-sm-4 px-lg-0">
      <div className="col-lg-4 col-md-5 offset-lg-1 text-center text-md-start">
        <h3 className="mb-lg-4">{title}</h3>
        <p>{content}</p>
      </div>
      <div className="col-lg-6 col-md-7 mt-md-4">
        <img src={imgSrc} className="d-block my-lg-2 mx-auto me-md-0" width={imgWidth} alt="Image" />
      </div>
    </div>
  </div>
);